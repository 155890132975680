<template>
  <section>
    <div class="card-content"
      :class="{ 'activities--container-full': isFullWidth, 'experiences--container': !isFullWidth }">
      <div v-for="(experience, index) in ExperiencesArray" :key="index">
        <TourCard v-if="!experience?.ticketLink" is-full-width :Tours="[experience]" class="experiences-card" />
        <ExternalActivityCard v-else is-full-width :Tickets="[experience]" class="experiences-card" />
      </div>
      
    </div>
    <b-button type="is-primary" class="mt-4" rounded v-if="experiencesCount <= ExperiencesList.length && showMore" outlined expanded
      @click="showMoreExperiences">Show more experiences</b-button>
  </section>
</template>

<script>
import TourCard from './elements/TourCard.vue';
import ExternalActivityCard from './elements/ExternalActivityCard.vue';
import { mapState } from 'vuex';
import { TicketsStates, ActivitiesStates } from '../store/Storetypes';

export default {
  data() {
    return {
      experiencesCount: 5
    }
  },
  props: {
    Experiences: {
      type: Array,
      default: () => []
    },
    Tickets: {
      type: Array,
      default: () => []
    },
    isFullWidth: {
      type: Boolean,
      default: true
    },
    isExperiencesIds: {
      type: Boolean,
      default: true
    },
    showMore: {
      type: Boolean,
      default: true
    },
  },
  components: {
    TourCard,
    ExternalActivityCard
  },
  methods: {
    getExperienceById(experiences, type = "tours") {
      return this[type].filter((element) => experiences.includes(element.id))
    },
    getExperiences(activitiesIds, ticketsIds) {
      const activities = this.getExperienceById(activitiesIds)
      const tickets = this.getExperienceById(ticketsIds, 'tickets')
      return [...activities, ...tickets]
    },
    showMoreExperiences() {
      this.experiencesCount += 5
      this.ExperiencesArray = this.ExperiencesList.slice(0, this.experiencesCount)
    },
    reset(){
      this.experiencesCount = 5
      this.getExperiences(this.Experiences, this.Tickets).slice(0, this.experiencesCount)
    }
  },
  computed: {
    ...mapState({
      tours: state => state.activities[ActivitiesStates.ACTIVITIES],
      tickets: state => state.tickets[TicketsStates.TICKETS]
    }),
    ExperiencesList() {
      if (!this.isExperiencesIds) {
        return [...this.Experiences, ...this.Tickets]
      }
      return this.getExperiences(this.Experiences, this.Tickets).slice(0, this.experiencesCount);

    },
    ExperiencesArray: {
      get() {
        return this.ExperiencesList.slice(0, this.experiencesCount);
      },
      set() {
        this.experiencesCount += 5;
      }

    }
  }


}
</script>

<style lang="scss" scoped>


.button.is-primary.is-outlined {
  background-color: transparent;
  border: 1px solid #f1f0f0;
  font-weight: 400;
  color: #0e3d4d;
}

@media (max-width: 769px) {
  .activities--container-full {
    grid-template-columns: repeat(1, minmax(min-content, 1fr)) !important;
  }
}

.activities--container-full {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(min-content, 1fr));

  .experiences-card {
    width: auto;
  }

  &.card-content {
    padding: 0 !important;
  }
}

.experiences--container {
  display: flex;
  gap: 1.5rem;
  overflow: auto;
}

.experiences-card {
  width: 78vw;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width: 40em) {
  .experiences-card {
    width: 54vw;
  }
}

/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 48em) {
  .experiences-card {
    width: 40vw;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 64em) {
  .experiences-card {
    width: 31vw;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 80em) {
  .experiences-card {
    width: 21vw;
  }
}

@media only screen and (min-width: 1700px) {
  .experiences-card {
    width: 18vw;
  }
}


@media only screen and (min-width: 2100px) {
  .experiences-card {
    width: 16vw;
  }
}


@media only screen and (min-width: 2350px) {
  .experiences-card {
    width: 14vw;
  }
}

.experience-card--mobile {
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin: 0 -20px;
  padding: 0 20px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.experiences--container {
  margin-right: -20px;
  margin-left: -20px;
  padding-left: 20px !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.card-content {
  padding: 0 20px 16px 0;
}
</style>