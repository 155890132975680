<template>
  <div class="activities--container" :class="{ 'activity-card--mobile': !isFullWidth}">
    <div class="card-info" v-for=" ticketData in Tickets" :key="ticketData.id" :data-aos="withAnimation ? 'fade-up' : null"
    :data-aos-easing="withAnimation ? 'ease-in-out' : null"
    :data-aos-mirror="withAnimation ? 'true' : null"
    :data-aos-once="withAnimation ? 'false' : null">
      <a :href="ticketData.ticketLink" target="_blank">
        <div class="img-container">
          <img class="card--image" :src="ticketData.coverImage" />
          <a :href="ticketData.ticketLink" target="_blank"><img class="arrow-btn" src="../../assets/open-circle.svg" /></a>
          <!--           <b-tag type="is-danger" class="featured-tag">Featured</b-tag> -->
        </div>
      </a>


      <div class="card--content">
        <div class="is-flex is-justify-content-space-between mt-1 ">

          <div v-if="ticketData.stars" class="is-flex is-justify-content-flex-end">
            <b-icon class="star-icon" size="is-small" pack="fas" icon="star"></b-icon> <span class="rating ml-1">{{
              ticketData.stars }}</span>
          </div>
        </div>

        <p class="title mb-1">{{ ticketData.title }}</p>
        <!--  <p v-if="ticketData.description" class="ticket-description mt-3 mb-3" v-html="ticketData.description"></p>
           -->
        <div v-if="ticketData.description">
          <b-collapse :open="false" position="is-bottom" aria-id="contentIdForA11y1">

            <template #trigger="props">
              <p class="ticket-description mt-3 mb-1" :class="{ 'ticket-description--minimized': !props.open }"
                v-html="ticketData.description"></p>
              <div class="is-flex" v-if="shouldShowCollapse(ticketData.description)">
                <a class="link" v-show="props.open">
                  Show less <b-icon icon="caret-up"></b-icon>
                </a>
                <a class="link" v-show="!props.open">
                  Show more <b-icon icon="caret-down"></b-icon>
                </a>
              </div>
            </template>

          </b-collapse>
        </div>


        <div class="price mt-2" :class="{ 'mb-2': isFullWidth }"><!-- <img class="mr-1"
            src="../../assets/ticket-outline.svg" /> -->{{ ticketData.price }}€</div>

      </div>


    </div>
  </div>
</template>

<script>
import { HelpersMixin } from '../../mixins'

export default {
  props: {
    Tickets: {
      type: Array,
      default: () => []
    },
    isFullWidth: {
      type: Boolean,
      default: true
    },
    isMobileViewOnly: {
      type: Boolean,
      default: false
    },
  },
  mixins: [HelpersMixin],
  methods: {
    shouldShowCollapse(description) {
      const lines = description.split(/\r\n|\r|\n/).length
      return lines > 2
    }
  }
};
</script>

<style lang="scss" scoped>
.has-text-info {

  .icon {
    margin-left: -5px;
  }
}

.ticket-description {
  font-size: .9rem;
  white-space: pre-line;
  line-height: 1.5rem;
  font-weight: 300;
  &--minimized {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.img-container {
  position: relative;
}
/*
@media (min-width: 769px) {
  .column.is-3 {
    flex: auto;
  }
}

@media (max-width: 769px) {
  .activities--container {
    grid-template-columns: repeat(1, minmax(min-content, 1fr)) !important;
  }
}*/


/*.activities--container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(min-content, 1fr));
}*/

.activity-details {
  /*font-weight: 600;
  opacity: 1;
  background-color: #FFF3B0;
  padding: 2px 6px;
  border-radius: 4px;*/
  font-weight: bold;
  color: #446DF6;
  padding: 6px 12px;
  border-radius: 23px;
  background-color: rgb(213 235 255 / 28%);
}


.card {
  .card-content {
    margin: 1.5rem 0 .95rem 0;
  }
}

.card--content {
  padding: 0.5rem;
}

.is-flex {
  align-items: center;
}

.rating {
  font-size: .8rem;
}

.title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin-top: .25rem;
}

.market-price {
  font-size: .9rem;
}

.title {
  margin-bottom: .4rem;
  font-size: 1.1rem;
  
}

.price {
  /* font-size: 0.9rem;
  color: #fff;
  background-color: #446DF6;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 13px;
  font-weight: 600;
  border-radius: 2px; */

  font-size: 1.05rem;
  border-radius: 8px;
  padding: 2px 0;
  /* background-color: #446DF6; */
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
  border-radius: 2px;
  display: flex;
  align-items: center;
}



.columns {
  margin-bottom: 0;
  align-items: flex-start;

  .column {
    padding: 0 0.75rem;
    height: auto;
  }
}

.row {
  display: inline-flex;
  align-items: center;
  color: #0e3d4dbf;
  margin-bottom: 4px;

  img {
    margin-right: 8px;
  }
}


.tag:not(body) {
  margin: 8px 0px;
}

.card--image {
  height: 200px;
  border-radius: 16px;
  width: -webkit-fill-available;
  object-fit: cover;
}

.activity-card--mobile {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin: 0 -20px;
  padding: 0 20px;

  .card-info {
    width: 70vw;
    flex: 0 0 auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.arrow-btn{
  position: absolute;
    bottom: -10px;
    right: -5px;
    height: 50px;
}

::v-deep {
  .featured-tag {
    position: absolute;
    top: 0;
    left: 8px;
    margin: 4px
  }

  .card {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 8px;
  }

  .card-content {
    background-color: transparent;
    padding: 0px;
  }
}
</style>
